const lightSchema = '(prefers-color-scheme: light)'

const clientThemeCode = String.raw`
(() => {
  const theme = window.matchMedia(${ JSON.stringify( lightSchema ) }).matches
    ? 'light'
    : 'dark';
  
  const cl = document.documentElement.classList;
  const dataAttr = document.documentElement.dataset.theme;

  if (dataAttr != null) {
    const themeAlreadyApplied = dataAttr === 'light' || dataAttr === 'dark';
    if (!themeAlreadyApplied) {
      document.documentElement.dataset.theme = theme;
    }
  } else {
    const themeAlreadyApplied = cl.contains('light') || cl.contains('dark');
    if (!themeAlreadyApplied) {
      cl.add(theme);
    }
  }
  
  const meta = document.querySelector('meta[name=color-scheme]');
  if (meta) {
    if (theme === 'dark') {
      meta.content = 'dark light';
    } else if (theme === 'light') {
      meta.content = 'light dark';
    }
  }
})();
`

type ThemeScriptProps = {
  hasSsrTheme: boolean;
}

export const ThemeScript = ( { hasSsrTheme }: ThemeScriptProps ) => {
  if ( hasSsrTheme ) {
    return null;
  }

  return (
    <script
      dangerouslySetInnerHTML={ { __html: clientThemeCode } }
      suppressHydrationWarning
    />
  )
}